import axios from "axios";
import { getToken } from "../utilities/tokenHandler";
const apiUrl = process.env.REACT_APP_API_URL;

export async function createTask(data) {
    let token = getToken();
    if (!token) {
        return;
    }

    const res = await axios.post(`${apiUrl}/tasks`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    return res;
}


export async function getTaskById(id) {
    let token = getToken();
    if (!token) {
        return;
    }

    const res = await axios.get(`${apiUrl}/tasks/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    return res;
}