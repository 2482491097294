import React from "react";
import "./landing.scss";

export default function Terms() {
    return (
        <div className="family__public__main">
            <h1>Terms and Conditions</h1>
            <p>
                These Terms of Service ("Terms") govern your use of our software-as-a-service (SaaS) solution ("Service"). By using the Service, you agree to these Terms. Please read them carefully.
            </p>
            <div>
                <h2>Transparancy!</h2>
                <p>You will use this as it is and as you find it.</p>
                <p></p>
            </div>
            <div>
                <h2>Service Description</h2>
                <p>Our Service provides a family organizer tool to help manage and coordinate family activities and schedules. The Service is offered as a subscription-based model with a clear monthly fee.</p>
            </div>
            <div>
                <h2>User Data</h2>
                <ul>
                    <li>Data Usage: We do not use your data to train AI, sell your information, or profit from it in any other way except through the subscription fees you pay.</li>
                    <li>Privacy: We are committed to protecting your privacy. Your data will only be used to provide and improve the Service. We will not share your data with third parties except as required by law.</li>
                </ul>
            </div>
            <div>
                <h2>Subscription and Payment</h2>
                <ul>
                    <li>Fees: The Service is available for a monthly fee. The current fee is linked to each different subscription type, starting from 5$ per month.</li>
                    <li>Billing: Subscription fees are billed monthly. You can cancel your subscription at any time, and you will continue to have access to the Service until the end of your billing period.</li>
                </ul>
            </div>
            <div>
                <h2>User Responsibilities</h2>
                <ul>
                    <li>Account Security: You are responsible for maintaining the security of your account and any activities that occur under your account.    </li>
                    <li>Usage: You agree to use the Service only for lawful purposes and in accordance with these Terms.</li>
                </ul>
            </div>
            <div>
                <h2>Modifications to the Service</h2>
                <p>We may update or modify the Service from time to time to improve functionality and user experience. Any changes to the Service will be communicated to you through our website or directly via email.</p>
            </div>

            <div>
                <h2>Termination</h2>
                <p>We reserve the right to terminate or suspend your account if you violate these Terms. You can also terminate your account at any time by cancelling your subscription.</p>
            </div>
            <div>
                <h2>Liability</h2>
                <p>Our Service is provided "as is" and we make no warranties or guarantees about its reliability or accuracy. To the maximum extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising out of your use of the Service.</p>
            </div>
            <div>
                <h2>Governing Law</h2>
                <p>These Terms are governed by and construed in accordance with the laws of Norway / European Union. Any disputes arising under or in connection with these Terms shall be resolved by the courts of Norway / European Union.</p>
            </div>
            <div>
                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about these Terms, please contact us at admin@klikkit.no</p>
            </div>
            <p>
                By using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
        </div>
    )
}