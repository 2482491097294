export default function getLandingLanguage(languages) {
    if (languages?.length > 0) {
        for (let l of languages) {
            if (no.codes?.includes(l)) {
                return no;
            }
            if (sv.codes?.includes(l)) {
                return sv;
            }
            if (da.codes?.includes(l)) {
                return da;
            }
            if (en.codes?.includes(l)) {
                return en;
            }
            if (fr.codes?.includes(l)) {
                return fr;
            }
            if (es.codes?.includes(l)) {
                return es;
            }
            if (it.codes?.includes(l)) {
                return it;
            }
        }
    }
    return en;
}

export function getLanguageList() {
    let allLanguages = [];
    allLanguages.push({ name: no.type, code: no.codes[0] });
    allLanguages.push({ name: sv.type, code: sv.codes[0] });
    allLanguages.push({ name: da.type, code: da.codes[0] });
    allLanguages.push({ name: en.type, code: en.codes[0] });
    allLanguages.push({ name: fr.type, code: fr.codes[0] });
    allLanguages.push({ name: es.type, code: es.codes[0] });
    allLanguages.push({ name: it.type, code: it.codes[0] });

    return allLanguages;
}

const no = {
    type: "Norsk",
    codes: ["nb", "nn", "no-NB", "nb-NO"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send oss en melding! Har du noen tilbakemeldinger, eller opplevd noe som du ønsker at blir forbedret?",
    title: "Familie Vertøykassen",
    subtitle: "Skaff verktøyene du trenger for å gjøre familielivet enklere.",
    why: {
        title: "Hvorfor velge vår Familieorganisator?",
        firstParagraph: "Forenkle håndteringen av familiens daglige gjøremål. Med vår organisator kan du holde oversikt over timeplaner, tildele oppgaver, planlegge måltider og holde kontakten – alt på ett sted. Den er designet for å hjelpe travle familier med å holde orden uten stress.",
        secondParagraph: "Vi bygger for øyeblikket en ny, brukervennlig Familieorganisator-verktøykasse. Meld deg på som Alpha-tester og vær den første til å prøve den. Hva betyr dette? Når Alpha-versjonen er klar, vil du motta en e-post med instruksjoner om hvordan du begynner å bruke verktøykassen og hjelper oss med å sørge for at den blir så god som mulig for din familie.",
    },
    features: [
        {
            title: "Delte Kalendere",
            text: "Hold alles timeplaner synkronisert med en delt familiekalender som sørger for at du aldri går glipp av viktige hendelser eller avtaler.",
        },
        {
            title: "Påmeldinger",
            text: "Lurer du på hvem som kommer på barnebursdagen? Send ut påmelding direkte i fra verktøykassen din, og få inn de detaljene du trenger.",
        },
        {
            title: "Familieregler",
            text: "Hver familie er unik, og vi håndterer ting forskjellig, men hvis du ønsker noen retningslinjer eller regler, vil du finne et verktøy for dette her.",
        },
        {
            title: "Oppgavehåndtering",
            text: "Tildel og følg med på husarbeid og ansvarsområder, og sørg for at alle bidrar og at ingenting blir oversett.",
        },
        {
            title: "Handlelister",
            text: "Opprett og del handlelister for å effektivisere shoppingturer og sikre at du aldri glemmer en vare. Vi elsker maler.",
        },
        {
            title: "Meldinger",
            text: "Ditt privatliv er vår prioritet. Vi skaper en sikker og kryptert løsning for dine private meldinger.",
        },
        {
            title: "Familieoppskrifter",
            text: "Bestemors hemmelige oppskrifter, din onkels fantastiske kjeks og den hemmelige sausen er alltid tilgjengelig for hele familien.",
        }
    ],
    learn: {
        title: "Interessert i å teste eller lære mer?",
        text: "Vær blant de første til å prøve vår Familieorganisator! Ved å bli med i vårt testprogram får du tidlig tilgang og muligheten til å påvirke utviklingen med din tilbakemelding. Meld deg på i dag for å starte med å gjøre familieorganiseringen enklere og mer strømlinjeformet.",
    },
    form: {
        name: "Ditt navn",
        firstname: "Fornavn",
        lastname: "Etternavn",
        password: "Passord",
        email: "E-post",
        location: "Plassering",
        phone: "Telefon",
        sufix: "Landekode",
        signup: "Meld deg på",
        comment: "Ønsker du å være med på testing, eller bare har spørsmål?",
        submit: "Send",
        thankYou: "Tusen takk for at du ønsker å være med på dette!",
        thankYouText: "Vi er glade for å ha deg med på denne reisen for å forenkle familieforvaltning. Når vi forbereder oss for vår testfase, vil vi sørge for å holde deg oppdatert med alle de siste utviklingene. Forvent å høre fra oss snart med flere detaljer!",
        thankYouTextEnd: "Takk igjen for din interesse for Familieorganisatoren — ditt fremtidige verktøy for å takle gledene og utfordringene i det moderne familielivet.",
        signupDetails: "Ved å melde deg på Alpha-testingen godtar du at vi kan lagre opplysningene dine, inntil vi kontakter deg for å tilby deg muligheten til første påmelding.",
    },
    timeline: {
        title: "Hva er tidslinjen?",
        data: [
            {
                title: "Sommer 2024",
                text: "Vi avklarer alle funksjoner og setter planene klare før testingen starter til høsten."
            },
            {
                title: "Høst 2024",
                text: "Vi starter Alpha-testing av vår webapplikasjon. Denne moderne løsningen er spesielt utviklet for dagens familier.",
            },
            {
                title: "Vinter 2024-25",
                text: "Webapplikasjonen vil offisielt bli lansert, og vi begynner å ta imot påmeldinger.",
            },
            {
                title: "Sommer 2025",
                text: "Vår mobilapplikasjon blir lansert, og utvider våre tjenester for tilgang på farten.",
            }
        ]
    },
    faq: {
        title: "Spørsmål og svar",
        data: [
            {
                question: "Hva kan vi gjøre i Familie appen?",
                answer: "Familieorganisatoren inkluderer delte kalendere, oppgavehåndtering, handlelister, familiemeldinger med beskyttelse av personvern, og en spesiell seksjon for familieoppskrifter. Den er designet for å hjelpe deg med å håndtere familiens planer, oppgaver og kommunikasjon mer effektivt."
            },
            {
                question: "Hvordan blir jeg med på testingen?",
                answer: "Du kan melde deg på Alpha-testing ved å besøke vår nettside og legge inn detaljene dine. Vi vil varsle deg med ytterligere instruksjoner når testfasen er i ferd med å begynne."
            },
            {
                question: "Kan jeg bruke appen på mobil?",
                answer: "Ja, vi starter først med web-applikasjonen og denne er 100% mobil vennlig og kan logges på med alle enheter. Vi kommer også ut med applikasjoner for Apple og andre markedsplasser i løpet av sommeren 2025."
            },
            {
                question: "Hvordan er privatlivet tatt hånd om?",
                answer: "Ditt privatliv er vår høyeste prioritet. Vi bruker ende-til-ende-kryptering for all kommunikasjon innenfor appen for å sikre at dine personlige opplysninger og meldinger forblir sikre og private."
            },
            {
                question: "Hvordan kan jeg tilpasse dette til min moderne familie?",
                answer: "Familier kan deles og en person kan være med i flere familier, har dere en bestemor som kanskje har mange barn selv? Kjekt å være med å planlegge alle sammen. Har du delt omsorg? Pass på at mamma og pappa har god oversikt på avtaler og timeplaner."
            }
        ]

    },
    newsletter: {
        error: "Feilmelding",
        errorMessage: "En feil har skjedd når du meldte deg til nyhetsbrevet, prøv gjerne igjen en annen gang.",
        success: "Tusen takk!",
        successMessage: "Du har nå meldt deg på nyhetsbrevet vårt."
    },
    footer: {
        company: "Familie verktøykassen er et produkt. Vi har lang erfaring med å lage gode produkter som er enkle å bruke. Vi gleder oss til å dele denne opplevelsen med dere og deres familier.",
        newsletterTitle: "Meld deg på vårt nyhetsbrev",
        name: "Navn",
        email: "E-post",
        submit: "Send"
    }

}
const da = {
    type: "Dansk",
    codes: ["da", "da-DK"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send us a message! Do you have feedback while testing or trying out the family toolbox?",
    title: "Familie Redskabskassen",
    subtitle: "Få værktøjskassen, du har brug for, til at forenkle din families logistik",
    why: {
        title: "Hvorfor skal du bruge Familieorganisatøren?",
        firstParagraph: "Gør det daglige familiestyring nemmere. Med vores organisatør kan du holde styr på skemaer, tildele opgaver, planlægge måltider og holde forbindelsen – alt sammen på ét sted. Den er designet til at hjælpe travle familier med at holde orden uden besvær.",
        secondParagraph: "Vi er i gang med at bygge en ny, nem at bruge Familieorganisatør-værktøjskasse. Tilmeld dig som Alpha tester og vær den første til at prøve den. Hvad betyder det? Når Alpha-versionen er klar, vil du få en e-mail med instruktioner om, hvordan du starter med at bruge værktøjskassen og hjælpe os med at sikre, at den er så god som mulig for din familie.",
    },
    features: [
        {
            title: "Fælles Kalendere",
            text: "Hold alles skemaer synkroniseret med en fælles familiekalender, der sikrer, at du aldrig går glip af vigtige begivenheder eller aftaler.",
        },
        {
            title: "Tilmeldinger",
            text: "Undrer du dig over, hvem der kommer til børnefødselsdagen? Send en tilmelding direkte fra din værktøjskasse og modtag de detaljer, du har brug for."
        },
        {
            title: "Familie Regler",
            text: "Hver familie er unik, vi håndterer alle ting forskelligt, men hvis du ønsker nogle retningslinjer eller regler, vil du finde et værktøj til dette her.",
        },
        {
            title: "Opgavestyring",
            text: "Tildel og spor huslige pligter og ansvar, og sørg for at alle bidrager, og intet bliver overset.",
        },
        {
            title: "Indkøbslister",
            text: "Opret og del indkøbslister for at strømline indkøbsture og sikre, at du aldrig glemmer en vare. Vi elsker skabeloner.",
        },
        {
            title: "Beskeder",
            text: "Dit privatliv er vores prioritet. Vi skaber en sikker og krypteret løsning til dine private beskeder.",
        },
        {
            title: "Familieopskrifter",
            text: "Bedstemors hemmelige opskrifter, din onkels fantastiske småkager og den hemmelige sauce er altid tilgængelig for hele familien.",
        }
    ],
    learn: {
        title: "Interesseret i at teste eller lære mere?",
        text: "Vær blandt de første til at prøve vores Familieorganisatør! Ved at deltage i vores testprogram får du tidlig adgang og har mulighed for at påvirke dens udvikling med din feedback. Tilmeld dig i dag for at begynde at gøre familieorganisationen mere glat og enkel.",
    },
    form: {
        name: "Navn",
        firstname: "Fornavn",
        lastname: "Efternavn",
        password: "Adgangskode",
        email: "Email",
        location: "Hvor befinder du dig?",
        comment: "Hvorfor vil du være en Alpha Tester, eller har du nogle spørgsmål?",
        submit: "Send",
        thankYou: "Tak for din tilmelding!",
        thankYouText: "Vi er begejstrede for at have dig med på denne rejse for at forenkle familieadministrationen. Mens vi gør klar til testfasen, vil vi sørge for at holde dig opdateret med alle de seneste udviklinger. Forvent at høre fra os snart med flere detaljer!",
        thankYouTextEnd: "Tak igen for din interesse i Familieorganisatøren – dit fremtidige redskab til at tackle glæderne og udfordringerne i det moderne familieliv.",
        signupDetails: "Ved at tilmelde dig Alpha testningen accepterer du, at vi kan opbevare dine oplysninger, indtil vi responderer for at tilbyde dig den første tilmeldingsmulighed.",
        sufix: "Suffiks",
        signup: "Tilmeld",
    },
    timeline: {
        title: "Hvad er tidsplanen?",
        data: [
            {
                title: "Efterår 2024",
                text: "Vi begynder Alpha-test af vores webapplikation. Denne moderne løsning er specifikt designet til nutidens familiers behov.",
            },
            {
                title: "Vinter 2024-25",
                text: "Webapplikationen vil officielt blive lanceret, og vi vil begynde at acceptere tilmeldinger.",
            },
            {
                title: "Forår/Sommer 2025",
                text: "Vores mobilapplikation vil blive lanceret, hvilket udvider vores tjenester til adgang på farten.",
            }
        ]
    },
    faq: {
        title: "Ofte Stillede Spørgsmål",
        data: [
            {
                question: "Hvilke funktioner indeholder Familieorganisatøren?",
                answer: "Familieorganisatøren inkluderer fælles kalendere, opgavestyring, indkøbslister, familiebeskeder med beskyttelse af privatlivet og en særlig sektion for familieopskrifter. Den er designet til at hjælpe dig med at håndtere din families skemaer, opgaver og kommunikation mere effektivt."
            },
            {
                question: "Hvordan tilmelder jeg mig Alpha-testen?",
                answer: "Du kan tilmelde dig Alpha-testen ved at besøge vores tilmeldingsside og indtaste dine detaljer. Vi vil underrette dig med yderligere instruktioner, når testfasen er ved at begynde."
            },
            {
                question: "Er Familieorganisatøren tilgængelig på mobile enheder?",
                answer: "Ja, Familieorganisatøren er først skabt som en web-app og er mobilvenlig fra dag ét. Mobilappen vil være tilgængelig på Apple og andre enheder fra forår/sommer 2025. Mobilapplikationen vil have fuld funktionalitet og giver dig mulighed for at håndtere din families opgaver og skemaer på farten."
            },
            {
                question: "Hvordan sikrer I privatlivets fred for vores data?",
                answer: "Beskederne er specielt krypteret inden for hver familie, de eneste personer, der kan læse dine beskeder, skal være en del af din familie. Dit privatliv er vores højeste prioritet. Vi bruger ende-til-ende-kryptering for alle kommunikationer inden for Familieorganisatøren for at sikre, at dine personlige oplysninger og beskeder forbliver sikre og private. Vi bruger også Cloud Flare til at sikre vores hjemmeside og håndhæve en masse moderne bedste praksisser."
            },
            {

                question: "Kan jeg tilpasse Familieorganisatøren til vores families behov?",
                answer: "Ja, Familieorganisatøren er meget tilpasningsdygtig. Du kan justere indstillinger, ændre meddelelsespræferencer og skræddersy funktionerne for bedre at passe til din families unikke dynamik og behov."
            }
        ]
    },
    newsletter: {
        error: "Fejl",
        errorMessage: "Der opstod en fejl under tilmelding til nyhedsbrevet.",
        success: "Tak!",
        successMessage: "Du er nu succesfuldt tilmeldt vores nyhedsbrev."
    },
    footer: {
        company: "Familieorganisatøren er et internt projekt. Vi har lang erfaring med at skabe gode produkter, der er nemme at bruge. Vi ser frem til at dele denne oplevelse med dig og din familie.",
        newsletterTitle: "Tilmeld dig vores nyhedsbrev",
        name: "Navn",
        email: "Email",
        submit: "Send"
    }
}
const sv = {
    type: "Svenska",
    codes: ["sv", "sv-SE"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send us a message! Do you have feedback while testing or trying out the family toolbox?",
    title: "Familje verktygslådan",
    subtitle: "Skaffa de verktyg du behöver för att göra familjelivet enklare.",
    why: {
        title: "Varför välja vår Familjeorganisatör?",
        firstParagraph: "Förenkla hanteringen av familjens dagliga sysslor. Med vår organisatör kan du hålla koll på scheman, tilldela uppgifter, planera måltider och hålla kontakten – allt på ett ställe. Den är utformad för att hjälpa sysselsatta familjer att hålla ordning utan krångel.",
        secondParagraph: "Vi bygger för närvarande en ny, användarvänlig verktygslåda för Familjeorganisatören. Anmäl dig som Alpha-testare och bli den första att prova den. Vad innebär detta? När Alpha-versionen är klar får du ett e-postmeddelande med instruktioner om hur du börjar använda verktygslådan och hjälper oss att se till att den blir så bra som möjligt för din familj.",
    },
    features: [
        {
            title: "Delade Kalendrar",
            text: "Håll allas scheman synkroniserade med en delad familjekalender som ser till att du aldrig missar viktiga händelser eller möten.",
        },
        {
            title: "Anmälningar",
            text: "Undrar du vem som kommer till barnkalaset? Skicka ut inbjudningar direkt från din verktygslåda och få alla detaljer du behöver."
        },
        {
            title: "Familjeregler",
            text: "Varje familj är unik, och vi hanterar saker olika, men om du vill ha några riktlinjer eller regler, hittar du ett verktyg för detta här.",
        },
        {
            title: "Uppgiftshantering",
            text: "Tilldela och följ upp hushållssysslor och ansvarsområden, och se till att alla bidrar och att inget blir förbisedd.",
        },
        {
            title: "Inköpslistor",
            text: "Skapa och dela inköpslistor för att effektivisera shoppingrundorna och se till att du aldrig glömmer en artikel. Vi älskar mallar.",
        },
        {
            title: "Meddelanden",
            text: "Din integritet är vår prioritet. Vi skapar en säker och krypterad lösning för dina privata meddelanden.",
        },
        {
            title: "Familjerecept",
            text: "Mormors hemliga recept, din farbrors fantastiska kakor och den hemliga såsen är alltid tillgängliga för hela familjen.",
        }
    ],
    learn: {
        title: "Intresserad av att testa eller lära dig mer?",
        text: "Var bland de första att prova vår Familjeorganisatör! Genom att delta i vårt testprogram får du tidig tillgång och möjlighet att påverka utvecklingen med din feedback. Anmäl dig idag för att börja göra familjeorganiseringen enklare och mer strömlinjeformad.",
    },
    form: {
        name: "Namn",
        firstname: "Förnamn",
        lastname: "Efternamn",
        password: "Lösenord",
        email: "E-post",
        sufix: "Suffix",
        signup: "Registrera dig",
        location: "Var befinner du dig?",
        comment: "Varför vill du bli en Alpha-testare, eller har du några frågor?",
        thankYou: "Tack för att du registrerade dig!",
        submit: "Skicka",
        thankYouText: "Vi är glada att ha dig med oss på denna resa för att förenkla familjehanteringen. När vi förbereder oss för vår testfas kommer vi att hålla dig uppdaterad med alla de senaste utvecklingarna. Förvänta dig att höra från oss snart med fler detaljer!",
        thankYouTextEnd: "Tack ännu en gång för ditt intresse för Family Organizer — ditt framtida verktyg för att hantera glädjen och utmaningarna i det moderna familjelivet.",
        signupDetails: "Genom att registrera dig för Alpha-testning godkänner du att vi kan lagra dina uppgifter tills vi svarar för att erbjuda dig den initiala registreringsmöjligheten."
    },
    timeline: {
        title: "Vad är tidslinjen?",
        data: [
            {
                title: "Sommar 2024",
                text: "Vi klargör alla funktioner och ställer in planerna klara innan testningen börjar till hösten."
            },
            {
                title: "Höst 2024",
                text: "Vi börjar Alpha-testningen av vår webbapplikation. Denna moderna lösning är särskilt utvecklad för dagens familjer.",
            },
            {
                title: "Vinter 2024-25",
                text: "Webbapplikationen kommer officiellt att lanseras, och vi börjar ta emot anmälningar.",
            },
            {
                title: "Sommar 2025",
                text: "Vår mobilapplikation lanseras och utökar våra tjänster för tillgång på språng.",
            }
        ]
    },
    faq: {
        title: "Frågor och svar",
        data: [
            {
                question: "Vad kan vi göra i familjeappen?",
                answer: "Familjeorganisatören inkluderar delade kalendrar, uppgiftshantering, inköpslistor, familjemeddelanden med skydd av privatlivet och en särskild sektion för familjerecept. Den är designad för att hjälpa dig hantera familjens scheman, uppgifter och kommunikation mer effektivt."
            },
            {
                question: "Hur deltar jag i testningen?",
                answer: "Du kan anmäla dig till Alpha-testningen genom att besöka vår anmälningssida och ange dina uppgifter. Vi kommer att meddela dig med ytterligare instruktioner när testfasen är på väg att börja."
            },
            {
                question: "Kan jag använda appen på mobilen?",
                answer: "Ja, familjeorganisatören kommer att vara tillgänglig på mobila enheter från våren 2025. Mobilapplikationen kommer att ha full funktionalitet och låter dig hantera familjens uppgifter och scheman på språng."
            },
            {
                question: "Hur tas min integritet hand om?",
                answer: "Din integritet är vår högsta prioritet. Vi använder kryptering från ände till ände för all kommunikation inom familjeorganisatören för att säkerställa att dina personuppgifter och meddelanden förblir säkra och privata."
            },
            {
                question: "Hur kan jag anpassa detta till min moderna familj?",
                answer: "Ja, Familjeorganisatören är mycket anpassningsbar. Du kan justera inställningar, ändra anmälningspreferenser och skräddarsy funktionerna för att bättre passa din familjs unika dynamik och behov."
            }
        ]

    },
    newsletter: {
        error: "Fel",
        errorMessage: "Något gick fel när du försökte registrera dig för nyhetsbrevet.",
        success: "Tack!",
        successMessage: "Du är nu framgångsrikt registrerad för vårt nyhetsbrev."
    },
    footer: {
        company: "Family Organizer är en produkt. Vi har lång erfarenhet av att skapa bra produkter som är enkla att använda. Vi ser fram emot att dela denna upplevelse med dig och din familj.",
        newsletterTitle: "Prenumerera på vårt nyhetsbrev",
        name: "Namn",
        email: "E-post",
        submit: "Skicka"
    }

}
const en = {
    type: "English",
    codes: ["en", "en-US", "en-GB"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send us a message! Do you have feedback while testing or trying out the family toolbox?",
    title: "Family Organizer",
    subtitle: "Get the toolbox you need to simplify your family logistics",
    why: {
        title: "Why should you use the Family Organizer?",
        firstParagraph: "Make managing your family's day-to-day simpler. With our organizer, you can keep track of schedules, assign tasks, plan meals, and stay connected—all in one place. It's designed to help busy families stay organized without the fuss.",
        secondParagraph: "We're currently building a new, easy-to-use Family Organizer toolbox. Sign up as an Alpha tester and be the first to try it out. What does this mean? When the Alpha version is ready, you'll get an email with instructions on how to start using the toolbox and help us make sure it's the best it can be for your family.",
    },
    features: [
        {
            title: "Shared Calendars",
            text: "Keep everyone's schedule in sync with a shared family calendar that ensures you never miss important events or appointments.",
        },
        {
            title: "Sign-ups",
            text: "Wondering who will come to the kids’ birthday party? Send out sign-ups directly from your toolbox, and gather the details you need."
        },
        {
            title: "Family Rules",
            text: "Each family is Unique, we all handle things differently but if you want some guidelines or rules, you will find a tool for this here.",
        },
        {
            title: "Task Management",
            text: "Assign and track household chores and responsibilities, making sure everyone contributes and nothing gets overlooked.",
        },
        {
            title: "Grocery Lists",
            text: "Create and share grocery lists to streamline shopping trips and ensure you never forget an item. We love templates.",
        },
        {
            title: "Messages",
            text: "Your privacy is our Priority. We create a Safe and Encrypted solution for your Private Messaging.",
        },
        {
            title: "Family Recipes",
            text: "Grandmas Secret Recipes, your Uncles Amazing Cookies and the Secret Sauce is always available for the Entire Family.",
        }
    ],
    learn: {
        title: "Interested in Testing or Learning More?",
        text: "Be among the first to try our Family Organizer! By joining our Testing Program, you’ll gain early access and have the opportunity to influence its development with your feedback. Sign up today to start making family organization smoother and simpler.",
    },
    form: {
        name: "Name",
        email: "Email",
        firstname: "Firstname",
        lastname: "Lastname",
        password: "Password",
        sufix: "Sufix",
        signup: "Sign up",
        location: "Where are you located?",
        comment: "Why do you want to be an Apha Tester, or have any questions?",
        thankYou: "Thank you for signing up!",
        submit: "Send",
        thankYouText: "We're excited to have you join us on this journey to simplify family management. As we gear up for our testing phase, we’ll make sure to keep you updated with all the latest developments. Expect to hear from us soon with more details!",
        thankYouTextEnd: "Thank you once again for your interest in the Family Organizer — your future go-to tool for tackling the joys and challenges of modern family life.",
        signupDetails: "By signing up for the Alpha testing, you agree that we can store your details, until we respond to offer you the initial signup opportunity.",
    },
    timeline: {
        title: "What is the Timeline?",
        data: [
            {
                title: "Fall 2024",
                text: "We will begin Alpha testing of our web application. This modern solution is designed specifically for the needs of contemporary families.",
            },
            {
                title: "Winter 2024-25",
                text: "The web application will officially launch, and we will start accepting signups.",
            },
            {
                title: "Spring / Summer 2025",
                text: "Our mobile application will be launched, expanding our services for on-the-go access.",
            }
        ]
    },
    faq: {
        title: "Frequently Asked Questions",
        data: [
            {
                question: "What features does the Family Organizer include?",
                answer: "The Family Organizer includes shared calendars, task management, Grocery lists, family messaging with privacy protection, and a special section for family recipes. It’s designed to help you manage your family’s schedules, tasks, and communication more efficiently."
            },
            {
                question: "How do I sign up for the Alpha testing?",
                answer: "You can sign up for the Alpha testing by visiting our sign-up page and entering your details. We will notify you with further instructions once the testing phase is about to begin."
            },
            {
                question: "Is the Family Organizer available on mobile devices?",
                answer: "Yes, the Family Organizer is created first as an web-app and is Mobile friendly from day one. The Mobile app will be available on Apple and other devices starting Spring / Summer 2025. The mobile application will feature full functionality and allow you to manage your family’s tasks and schedules on the go."
            },
            {
                question: "How do you ensure the privacy of our data?",
                answer: "The messages are specially encrypted within each family, the only people that can read your messages must be a part of your family. Your privacy is our top priority. We use end-to-end encryption for all communications within the Family Organizer to ensure that your personal information and messages remain secure and private. We also use Cloud Flare to secure our website and enforce a lot of modern best-practices."
            },
            {
                question: "Can I customize the Family Organizer to suit our family’s needs?",
                answer: "Yes, the Family Organizer is highly customizable. You can adjust settings, modify notification preferences, and tailor the features to better fit your family’s unique dynamic and needs."
            }
        ]
    },
    newsletter: {
        error: "Error",
        errorMessage: "Something went wrong when trying to signup for the Newsletter.",
        success: "Thank you!",
        successMessage: "You are now successfully signed up for our Newsletter.",
    },
    footer: {
        company: "Family Organizer is passion project, created for busy families. We are excited to share this experience with you and your families.",
        newsletterTitle: "Sign up for our Newsletter",
        name: "Name",
        email: "Email",
        submit: "Send"
    }
}
const fr = {
    type: "Français",
    codes: ["fr", "fr-FR"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send us a message! Do you have feedback while testing or trying out the family toolbox?",
    title: "Organisateur Familial",
    subtitle: "Obtenez la boîte à outils dont vous avez besoin pour simplifier la logistique de votre famille",
    why: {
        title: "Pourquoi utiliser l'Organisateur Familial ?",
        firstParagraph: "Simplifiez la gestion quotidienne de votre famille. Avec notre organisateur, vous pouvez suivre les emplois du temps, assigner des tâches, planifier des repas et rester connecté — tout en un seul endroit. Il est conçu pour aider les familles occupées à rester organisées sans tracas.",
        secondParagraph: "Nous sommes actuellement en train de développer une nouvelle boîte à outils pour l'Organisateur Familial, facile à utiliser. Inscrivez-vous en tant que testeur Alpha et soyez le premier à l'essayer. Que signifie cela ? Lorsque la version Alpha sera prête, vous recevrez un email avec des instructions sur comment commencer à utiliser la boîte à outils et nous aider à la rendre aussi efficace que possible pour votre famille.",
    },
    features: [
        {
            title: "Calendriers Partagés",
            text: "Gardez les emplois du temps de tout le monde synchronisés avec un calendrier familial partagé qui vous assure de ne jamais manquer d'événements ou de rendez-vous importants.",
        },
        {
            title: "Inscriptions",
            text: "Vous vous demandez qui viendra à l'anniversaire des enfants? Envoyez des inscriptions directement depuis votre boîte à outils et recevez les détails dont vous avez besoin."
        },
        {
            title: "Règles Familiales",
            text: "Chaque famille est unique, nous gérons tous les choses différemment, mais si vous souhaitez des directives ou des règles, vous trouverez ici un outil pour cela.",
        },
        {
            title: "Gestion des Tâches",
            text: "Attribuez et suivez les tâches ménagères et les responsabilités, en vous assurant que tout le monde contribue et que rien n'est négligé.",
        },
        {
            title: "Listes de Courses",
            text: "Créez et partagez des listes de courses pour rationaliser les sorties shopping et assurez-vous de ne jamais oublier un article. Nous aimons les modèles.",
        },
        {
            title: "Messages",
            text: "Votre vie privée est notre priorité. Nous créons une solution sûre et cryptée pour vos messages privés.",
        },
        {
            title: "Recettes Familiales",
            text: "Les recettes secrètes de grand-mère, les incroyables cookies de votre oncle et la sauce secrète sont toujours disponibles pour toute la famille.",
        }
    ],
    learn: {
        title: "Intéressé par les tests ou voulez en savoir plus ?",
        text: "Soyez parmi les premiers à essayer notre Organisateur Familial ! En rejoignant notre programme de test, vous aurez un accès anticipé et la possibilité d'influencer son développement grâce à vos retours. Inscrivez-vous aujourd'hui pour commencer à rendre l'organisation familiale plus fluide et plus simple.",
    },
    form: {
        name: "Nom",
        firstname: "Prénom",
        lastname: "Nom de famille",
        password: "Mot de passe",
        email: "Email",
        sufix: "Indicatif pays",
        signup: "S'inscrire",
        location: "Où vous situez-vous ?",
        comment: "Pourquoi voulez-vous être un testeur Alpha, ou avez-vous des questions ?",
        submit: "Envoyer",
        thankYou: "Merci de vous être inscrit !",
        thankYouText: "Nous sommes ravis de vous avoir avec nous dans cette aventure pour simplifier la gestion familiale. Alors que nous nous préparons pour la phase de test, nous veillerons à vous tenir au courant de tous les derniers développements. Attendez-vous à recevoir de nos nouvelles bientôt avec plus de détails !",
        thankYouTextEnd: "Merci encore pour votre intérêt pour l'Organisateur Familial — votre outil de prédilection pour relever les joies et les défis de la vie familiale moderne.",
        signupDetails: "En vous inscrivant aux tests Alpha, vous acceptez que nous puissions stocker vos détails, jusqu'à ce que nous répondions pour vous offrir l'opportunité de vous inscrire initialement.",
    },
    timeline: {
        title: "Quel est le calendrier ?",
        data: [
            {
                title: "Automne 2024",
                text: "Nous commencerons les tests Alpha de notre application web. Cette solution moderne est spécialement conçue pour les besoins des familles contemporaines.",
            },
            {
                title: "Hiver 2024-25",
                text: "L'application web sera officiellement lancée, et nous commencerons à accepter les inscriptions.",
            },
            {
                title: "Printemps / Été 2025",
                text: "Notre application mobile sera lancée, élargissant nos services pour un accès en déplacement.",
            }
        ]
    },
    faq: {
        title: "Questions Fréquemment Posées",
        data: [
            {
                question: "Quelles fonctionnalités l'Organisateur Familial inclut-il ?",
                answer: "L'Organisateur Familial inclut des calendriers partagés, la gestion des tâches, les listes de courses, la messagerie familiale avec protection de la vie privée, et une section spéciale pour les recettes familiales. Il est conçu pour vous aider à gérer plus efficacement les emplois du temps, les tâches et la communication de votre famille."
            },
            {
                question: "Comment puis-je m'inscrire pour les tests Alpha ?",
                answer: "Vous pouvez vous inscrire pour les tests Alpha en visitant notre page d'inscription et en entrant vos détails. Nous vous notifierons avec des instructions supplémentaires une fois que la phase de test est sur le point de commencer."
            },
            {
                question: "L'Organisateur Familial est-il disponible sur les appareils mobiles ?",
                answer: "Oui, l'Organisateur Familial est d'abord créé comme une application web et est mobile friendly dès le premier jour. L'application mobile sera disponible sur Apple et d'autres appareils à partir du printemps/été 2025. L'application mobile offrira une fonctionnalité complète et vous permettra de gérer les tâches et les emplois du temps de votre famille en déplacement."
            },
            {
                question: "Comment assurez-vous la confidentialité de nos données ?",
                answer: "Les messages sont spécialement cryptés au sein de chaque famille, les seules personnes pouvant lire vos messages doivent faire partie de votre famille. Votre vie privée est notre priorité absolue. Nous utilisons un cryptage de bout en bout pour toutes les communications au sein de l'Organisateur Familial pour assurer que vos informations personnelles et vos messages restent sécurisés et privés. Nous utilisons également Cloud Flare pour sécuriser notre site web et appliquer beaucoup de meilleures pratiques modernes."
            },
            {
                question: "Puis-je personnaliser l'Organisateur Familial pour répondre aux besoins de notre famille ?",
                answer: "Oui, l'Organisateur Familial est hautement personnalisable. Vous pouvez ajuster les paramètres, modifier les préférences de notification et adapter les fonctionnalités pour mieux répondre à la dynamique unique et aux besoins de votre famille."
            }
        ]
    },
    newsletter: {
        error: "Erreur",
        errorMessage: "Un problème est survenu lors de l'inscription à la Newsletter.",
        success: "Merci !",
        successMessage: "Vous êtes maintenant inscrit avec succès à notre Newsletter."
    },
    footer: {
        company: "L'Organisateur Familial est un projet. Nous sommes ravis de partager cette expérience avec vous et vos familles.",
        newsletterTitle: "Inscrivez-vous à notre newsletter",
        name: "Nom",
        email: "Email",
        submit: "Envoyer"
    }
}
const it = {
    type: "Italiano",
    codes: ["it", "it-IT"],
    terms: "Terms of Service",
    feedbackPlaceholder: "Send us a message! Do you have feedback while testing or trying out the family toolbox?",
    title: "Cassetta degli Attrezzi per la Famiglia",
    subtitle: "Ottieni gli strumenti di cui hai bisogno per semplificare la vita familiare.",
    why: {
        title: "Perché scegliere il nostro Organizzatore di Famiglia?",
        firstParagraph: "Semplifica la gestione quotidiana della tua famiglia. Con il nostro organizzatore, puoi tenere traccia degli orari, assegnare compiti, pianificare i pasti e mantenere il contatto, tutto in un unico posto. È progettato per aiutare le famiglie impegnate a rimanere organizzate senza stress.",
        secondParagraph: "Attualmente stiamo sviluppando una nuova cassetta degli attrezzi per l'Organizzatore di Famiglia facile da usare. Iscriviti come tester Alpha e sii il primo a provarlo. Cosa significa questo? Quando la versione Alpha sarà pronta, riceverai un'email con le istruzioni su come iniziare a utilizzare la cassetta degli attrezzi e aiutarci a renderla il più utile possibile per la tua famiglia.",
    },
    features: [
        {
            title: "Calendari Condivisi",
            text: "Mantieni sincronizzati i calendari di tutti con un calendario familiare condiviso che ti assicura di non perdere mai eventi o appuntamenti importanti.",
        },
        {
            title: "Regole di Famiglia",
            text: "Ogni famiglia è unica, e gestiamo le cose in modo diverso, ma se desideri alcune linee guida o regole, troverai qui uno strumento per questo.",
        },
        {
            title: "Iscrizioni",
            text: "Ti chiedi chi verrà al compleanno del bambino? Invia un'iscrizione direttamente dalla tua cassetta degli attrezzi e ottieni i dettagli di cui hai bisogno."
        },
        {
            title: "Gestione dei Compiti",
            text: "Assegna e tieni traccia delle faccende domestiche e delle responsabilità, assicurandoti che tutti contribuiscano e che nulla venga trascurato.",
        },
        {
            title: "Liste della Spesa",
            text: "Crea e condividi liste della spesa per razionalizzare le visite al supermercato e assicurarti di non dimenticare mai un articolo. Amiamo i modelli.",
        },
        {
            title: "Messaggi",
            text: "La tua privacy è la nostra priorità. Creiamo una soluzione sicura e criptata per i tuoi messaggi privati.",
        },
        {
            title: "Ricette di Famiglia",
            text: "Le ricette segrete della nonna, i fantastici biscotti del tuo zio e la salsa segreta sono sempre disponibili per tutta la famiglia.",
        }
    ],
    learn: {
        title: "Interessato a testare o a saperne di più?",
        text: "Sii tra i primi a provare il nostro Organizzatore di Famiglia! Unendoti al nostro programma di test, avrai accesso anticipato e la possibilità di influenzare lo sviluppo con i tuoi feedback. Iscriviti oggi per iniziare a rendere l'organizzazione familiare più semplice e lineare.",
    },
    form: {
        name: "Il tuo nome",
        firstname: "Nome",
        lastname: "Cognome",
        password: "Password",
        email: "Email",
        sufix: "Prefisso internazionale",
        signup: "Iscriviti",
        location: "Località",
        comment: "Vuoi partecipare ai test o hai solo delle domande?",
        submit: "Invia",
        thankYou: "Grazie mille per voler partecipare a questo!",
        thankYouText: "Siamo felici di averti con noi in questo viaggio per semplificare la gestione familiare. Mentre ci prepariamo per la nostra fase di test, ci assicureremo di tenerti aggiornato con tutti gli ultimi sviluppi. Aspettati di sentire presto da noi con più dettagli!",
        thankYouTextEnd: "Grazie ancora per il tuo interesse nell'Organizzatore di Famiglia — il tuo futuro strumento per affrontare le gioie e le sfide della vita familiare moderna.",
        signupDetails: "Iscrivendoti al test Alpha, accetti che possiamo conservare i tuoi dati fino a quando non ti contatteremo per offrirti l'opportunità di iscrizione iniziale.",
    },
    timeline: {
        title: "Qual è la cronologia?",
        data: [
            {
                title: "Estate 2024",
                text: "Chiariremo tutte le funzionalità e stabiliremo i piani prima che inizi la fase di test autunnale."
            },
            {
                title: "Autunno 2024",
                text: "Inizieremo i test Alpha della nostra applicazione web. Questa soluzione moderna è appositamente sviluppata per le famiglie odierne.",
            },
            {
                title: "Inverno 2024-25",
                text: "L'applicazione web sarà ufficialmente lanciata, e inizieremo a ricevere le iscrizioni.",
            },
            {
                title: "Estate 2025",
                text: "La nostra applicazione mobile sarà lanciata, espandendo i nostri servizi per l'accesso in movimento.",
            }
        ]
    },
    faq: {
        title: "Domande Frequenti",
        data: [
            {
                question: "Cosa possiamo fare nell'app della Famiglia?",
                answer: "L'Organizzatore di Famiglia include calendari condivisi, gestione dei compiti, liste della spesa, messaggi familiari con protezione della privacy, e una sezione speciale per le ricette di famiglia. È progettato per aiutarti a gestire i piani, i compiti e la comunicazione della tua famiglia in modo più efficace."
            },
            {
                question: "Come posso partecipare ai test?",
                answer: "Puoi iscriverti ai test Alpha visitando il nostro sito web e inserendo i tuoi dettagli. Ti avviseremo con ulteriori istruzioni non appena la fase di test sta per iniziare."
            },
            {
                question: "Posso usare l'app sul cellulare?",
                answer: "Sì, iniziamo prima con l'applicazione web che è completamente compatibile con i dispositivi mobili e può essere accessibile da tutti i dispositivi. Rilasceremo anche applicazioni per Apple e altri marketplace durante l'estate del 2025."
            },
            {
                question: "Come è tutelata la privacy?",
                answer: "La tua privacy è la nostra massima priorità. Utilizziamo la crittografia end-to-end per tutte le comunicazioni all'interno dell'app per garantire che le tue informazioni personali e i tuoi messaggi rimangano sicuri e privati."
            },
            {
                question: "Come posso personalizzare questo per la mia famiglia moderna?",
                answer: "Le famiglie possono essere divise e una persona può far parte di più famiglie, hai una nonna che magari ha molti figli? È utile essere coinvolti nella pianificazione di tutti insieme. Hai la custodia condivisa? Assicurati che mamma e papà abbiano una buona panoramica degli appuntamenti e degli orari."
            }
        ]

    },
    newsletter: {
        error: "Errore",
        errorMessage: "Si è verificato un errore durante la registrazione alla Newsletter.",
        success: "Grazie!",
        successMessage: "Ti sei iscritto con successo alla nostra Newsletter."
    },
    footer: {
        company: "Family Organizer è un prodotto. Siamo entusiasti di condividere questa esperienza con te e le tue famiglie.",
        newsletterTitle: "Iscriviti alla nostra newsletter",
        name: "Nome",
        email: "Email",
        submit: "Invia"
    }

}
const es = {
    type: "Español",
    codes: ["es", "es-ES"],
    terms: "Terms of Service",
    title: "Organizador Familiar",
    subtitle: "Obtén la caja de herramientas que necesitas para simplificar la logística de tu familia",
    why: {
        title: "¿Por qué deberías usar el Organizador Familiar?",
        firstParagraph: "Simplifica la gestión diaria de tu familia. Con nuestro organizador, puedes llevar un registro de los horarios, asignar tareas, planificar comidas y mantenerse conectado, todo en un solo lugar. Está diseñado para ayudar a las familias ocupadas a mantenerse organizadas sin complicaciones.",
        secondParagraph: "Actualmente estamos construyendo una nueva caja de herramientas del Organizador Familiar fácil de usar. Regístrate como probador Alpha y sé el primero en probarlo. ¿Qué significa esto? Cuando la versión Alpha esté lista, recibirás un correo electrónico con instrucciones sobre cómo comenzar a usar la caja de herramientas y ayudarnos a asegurarnos de que sea lo mejor posible para tu familia.",
    },
    features: [
        {
            title: "Calendarios Compartidos",
            text: "Mantén sincronizados los horarios de todos con un calendario familiar compartido que garantiza que nunca te pierdas eventos o citas importantes.",
        },
        {
            title: "Inscripciones",
            text: "¿Te preguntas quién vendrá a la fiesta de cumpleaños infantil? Envía inscripciones directamente desde tu caja de herramientas y obtén los detalles que necesitas."
        },
        {
            title: "Reglas Familiares",
            text: "Cada familia es única, todos manejamos las cosas de manera diferente, pero si deseas algunas pautas o reglas, encontrarás aquí una herramienta para ello.",
        },
        {
            title: "Gestión de Tareas",
            text: "Asigna y realiza un seguimiento de las tareas domésticas y responsabilidades, asegurando que todos contribuyan y nada se pase por alto.",
        },
        {
            title: "Listas de la Compra",
            text: "Crea y comparte listas de la compra para optimizar las salidas de compras y asegurarte de que nunca olvides un artículo. Nos encantan las plantillas.",
        },
        {
            title: "Mensajes",
            text: "Tu privacidad es nuestra prioridad. Creamos una solución segura y cifrada para tus mensajes privados.",
        },
        {
            title: "Recetas Familiares",
            text: "Las recetas secretas de la abuela, las increíbles galletas de tu tío y la salsa secreta siempre están disponibles para toda la familia.",
        }
    ],
    learn: {
        title: "¿Interesado en probarlo o aprender más?",
        text: "Sé uno de los primeros en probar nuestro Organizador Familiar. Al unirte a nuestro Programa de Pruebas, obtendrás acceso anticipado y tendrás la oportunidad de influir en su desarrollo con tus comentarios. Regístrate hoy para comenzar a hacer que la organización familiar sea más fluida y sencilla.",
    },
    form: {
        name: "Nombre",
        firstname: "Nombre",
        lastname: "Apellido",
        password: "Contraseña",
        email: "Correo electrónico",
        location: "¿Dónde te encuentras?",
        comment: "¿Por qué quieres ser un probador Alpha, o tienes alguna pregunta?",
        submit: "Enviar",
        sufix: "Código de país",
        signup: "Registrarse",
        thankYou: "¡Gracias por registrarte!",
        thankYouText: "Estamos emocionados de tenerte con nosotros en esta jornada para simplificar la gestión familiar. A medida que nos preparamos para la fase de prueba, nos aseguraremos de mantenerte informado sobre todos los últimos desarrollos. ¡Espera noticias nuestras pronto con más detalles!",
        thankYouTextEnd: "Gracias una vez más por tu interés en el Organizador Familiar — tu herramienta de referencia para afrontar las alegrías y desafíos de la vida familiar moderna.",
        signupDetails: "Al registrarte para las pruebas Alpha, aceptas que podemos almacenar tus detalles, hasta que respondamos para ofrecerte la oportunidad de inscripción inicial.",
    },
    timeline: {
        title: "¿Cuál es el cronograma?",
        data: [
            {
                title: "Otoño 2024",
                text: "Comenzaremos las pruebas Alpha de nuestra aplicación web. Esta solución moderna está diseñada específicamente para las necesidades de las familias contemporáneas.",
            },
            {
                title: "Invierno 2024-25",
                text: "La aplicación web se lanzará oficialmente, y comenzaremos a aceptar inscripciones.",
            },
            {
                title: "Primavera/Verano 2025",
                text: "Nuestra aplicación móvil se lanzará, expandiendo nuestros servicios para el acceso en movimiento.",
            }
        ]
    },
    faq: {
        title: "Preguntas Frecuentes",
        data: [
            {
                question: "¿Qué características incluye el Organizador Familiar?",
                answer: "El Organizador Familiar incluye calendarios compartidos, gestión de tareas, listas de la compra, mensajería familiar con protección de privacidad y una sección especial para recetas familiares. Está diseñado para ayudarte a gestionar los horarios, tareas y comunicaciones de tu familia de manera más eficiente."
            },
            {
                question: "¿Cómo me inscribo para las pruebas Alpha?",
                answer: "Puedes inscribirte para las pruebas Alpha visitando nuestra página de registro e introduciendo tus detalles. Te notificaremos con más instrucciones una vez que la fase de prueba esté a punto de comenzar."
            },
            {
                question: "¿Está disponible el Organizador Familiar en dispositivos móviles?",
                answer: "Sí, el Organizador Familiar se creó primero como una aplicación web y es amigable con dispositivos móviles desde el primer día. La aplicación móvil estará disponible en dispositivos Apple y otros a partir de la primavera/verano de 2025. La aplicación móvil contará con funcionalidad completa y te permitirá gestionar las tareas y horarios de tu familia en movimiento."
            },
            {
                question: "¿Cómo aseguran la privacidad de nuestros datos?",
                answer: "Los mensajes están especialmente cifrados dentro de cada familia, solo las personas que forman parte de tu familia pueden leer tus mensajes. Tu privacidad es nuestra máxima prioridad. Utilizamos cifrado de extremo a extremo para todas las comunicaciones dentro del Organizador Familiar para asegurar que tu información personal y mensajes permanezcan seguros y privados. También utilizamos Cloud Flare para asegurar nuestro sitio web y aplicar muchas de las mejores prácticas modernas."
            },
            {
                question: "¿Puedo personalizar el Organizador Familiar para adaptarlo a las necesidades de nuestra familia?",
                answer: "Sí, el Organizador Familiar es altamente personalizable. Puedes ajustar configuraciones, modificar preferencias de notificaciones y adaptar las características para que se ajusten mejor a la dinámica única y necesidades de tu familia."
            }
        ]
    },
    newsletter: {
        error: "Error",
        errorMessage: "Algo salió mal al intentar suscribirse al boletín.",
        success: "¡Gracias!",
        successMessage: "Ahora estás suscrito exitosamente a nuestro boletín."
    },
    footer: {
        company: "El Organizador Familiar es un proyecto. Estamos emocionados de compartir esta experiencia contigo y tus familias.",
        newsletterTitle: "Suscríbete a nuestro boletín",
        name: "Nombre",
        email: "Correo electrónico",
        submit: "Enviar"
    }
}

