export function saveToken(token) {
    // Save token in local storage
    if (typeof window !== "undefined") {
        localStorage.setItem('authToken', token);
    }
}

export function getToken() {
    // Fetch the token from local storage
    if (typeof window !== "undefined") {
        return localStorage.getItem('authToken');
    }
    return null; // Return null if not running in a browser environment
}