import React from "react";
import "./calendar.scss";

export default function Calendar({ date, setDate, weekStartsOn = 1, weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], dateClicked, onDateClick }) {

    if (typeof date === "undefined") {
        return (
            <div className="family__calendar">
                <div>Error: Missing Date...</div>
            </div>
        )
    }

    const moment = require('moment');
    let daysOfMonth = moment(date).daysInMonth();
    let firstDay = moment(date).startOf('month').day();

    let paddedDays = 0;

    if (firstDay !== weekStartsOn) {
        paddedDays = (firstDay - weekStartsOn + 7) % 7;
    }


    let displayedDate = new Date();
    if (date) {
        displayedDate = date;
    }
    return (
        <div className="family__calendar">
            <div className="calendar__head">
                <button type="button" onClick={() => {
                    setDate((prev) => {
                        return new Date(prev.getFullYear(), prev.getMonth() - 1, 1);
                    })
                }}>
                    <i className="fas fa-chevron-left"></i>
                </button>
                <span>{displayedDate.toLocaleDateString("default", {
                    month: "long",
                    year: "numeric"
                })}</span>

                <button type="button"
                    onClick={() => {
                        setDate((prev) => {
                            return new Date(prev.getFullYear(), prev.getMonth() + 1, 1);
                        })
                    }}
                >
                    <i className="fas fa-chevron-right"></i>
                </button>
            </div>
            <div className="calendar__body">
                {weekdays.map((day, index) => {
                    if (weekStartsOn === 0 || weekdays.indexOf(day) >= weekStartsOn) {
                        return (
                            <div key={index} className="calendar__day">
                                <span>{day}</span>
                            </div>
                        );
                    }
                    return null; // Ensure a default return to prevent warnings
                })}
                {weekStartsOn !== 0 && weekdays?.map((day, index) => {
                    console.log(day);
                    if (weekdays?.indexOf(day) < weekStartsOn) {
                        return (
                            <div key={index + weekdays?.length} className="calendar__day">
                                <span>{day}</span>
                            </div>
                        );
                    }
                    return null; // Ensure a default return to prevent warnings
                })}

                {[...Array(paddedDays).keys()].map((day) => {
                    return (
                        <div key={day} className="calendar__day disabled">
                            <span></span>
                        </div>
                    )
                })}
                {[...Array(daysOfMonth).keys()].map((day) => {
                    let isToday = false;
                    let isClicked = false;
                    let today = new Date();
                    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === day + 1) {
                        isToday = true;
                    }
                    if (dateClicked) {
                        let clicked = new Date(dateClicked);
                        if (clicked.getFullYear() === date.getFullYear() && clicked.getMonth() === date.getMonth() && clicked.getDate() === day + 1) {
                            isClicked = true;
                        }
                    }
                    let thisClass = isToday ? "calendar__day today" : "calendar__day";
                    if (isClicked) {
                        thisClass += " selected";
                    }
                    return (
                        <div key={day} className={thisClass}
                            onClick={() => {
                                const selectedDate = new Date(date.getFullYear(), date.getMonth(), day + 1);
                                selectedDate.setHours(12, 0, 0, 0); // Set the time to 12:00:00
                                onDateClick(selectedDate);
                            }}
                        >
                            <span>{day + 1}</span>
                        </div>
                    )
                }
                )}

            </div>
        </div>
    )
}