import React, { useContext, useState } from "react";
import HubContext from "../../../context/HubContext";
import { createTaskType } from "../../../clients/taskTypes";

const iconOptions = [
    { name: "Briefcase", icon: "fa fa-briefcase" },
    { name: "Calendar", icon: "fa fa-calendar" },
    { name: "Soap", icon: "fa-regular fa-soap" },
    { name: "Utensils", icon: "fa fa-utensils" },
    { name: "Tshirt", icon: "fa fa-tshirt" },
    { name: "Medication", icon: "fa fa-pills" },
    { name: "Pet Care", icon: "fa fa-paw" },
    { name: "Shopping", icon: "fa fa-shopping-cart" },
    { name: "Trash", icon: "fa fa-trash" },
    { name: "Vacuum", icon: "fa-solid fa-vacuum" },
    { name: "Plants", icon: "fa fa-seedling" },
    { name: "Other", icon: "fa fa-question" },
    { name: "Bed", icon: "fa-solid fa-bed" },
    { name: "Dumbbell", icon: "fa-solid fa-dumbbell" },
    { name: "Laundry", icon: "fa-solid fa-washing-machine" },
]

export default function NewTaskType() {
    const { activeFamily } = useContext(HubContext);
    const [taskType, setTaskType] = useState({
        name: "",
        icon: "fa fa-briefcase",
        hex_color: "#000000",
        family_id: activeFamily.id
    });


    return (
        <div>
            <h1>New Task Type</h1>
            <label>
                <span>Task Name</span>
                <input type="text" value={taskType.name} onChange={(e) => setTaskType({ ...taskType, name: e.target.value })} />
            </label>
            <label>
                <span>Icon</span>
                <select value={taskType.icon} onChange={(e) => setTaskType({ ...taskType, icon: e.target.value })}>
                    {iconOptions.map((icon, index) => {
                        return (
                            <option key={index} value={icon.icon}>{icon.name}</option>
                        )
                    })}
                </select>
            </label>
            <label>
                <span>Color</span>
                <input type="color" value={taskType.hex_color} onChange={(e) => setTaskType({ ...taskType, hex_color: e.target.value })} />
            </label>

            <button onClick={async () => {
                try {
                    const res = await createTaskType(taskType);
                    console.log(res);
                } catch (err) {
                    console.error(err);
                }
            }}>Create new Type</button>


            <div className="task_type__container">
                <div className="task_type__card" style={{
                    border: "1px solid " + taskType.hex_color,
                    backgroundColor: taskType.hex_color,
                }}>
                    <i className={taskType.icon} ></i>
                    {taskType.name}</div>
            </div>
        </div>
    )
}