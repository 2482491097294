import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Terms from "./pages/Landing/Terms";
import Signup from "./pages/Signup";
import ComponentLoading from "./components/ComponentLoading";
import NewTask from "./pages/Hub/Tasks/NewTask";
import NewTaskType from "./pages/Hub/Tasks/NewTaskType";
import ChatHub from "./pages/Hub/ChatHub";

const Home = lazy(() => import("./pages"));
const Landing = lazy(() => import("./pages/Landing"))
const Hub = lazy(() => import("./pages/Hub"))
const Calendar = lazy(() => import("./pages/Hub/Calendar"))
const Settings = lazy(() => import("./pages/Hub/Settings"))
const FamilySettings = lazy(() => import("./pages/Hub/Settings/FamilySettings"))
// Tasks
const Tasks = lazy(() => import("./pages/Hub/Tasks"))
const TaskOverview = lazy(() => import("./pages/Hub/Tasks/Overview"))
const EditTask = lazy(() => import("./pages/Hub/Tasks/EditTask"))
// ShoppingLists
const ShoppingLists = lazy(() => import("./pages/Hub/ShoppingLists"))
const List = lazy(() => import("./pages/Hub/ShoppingLists/List"))
const EditShoppingList = lazy(() => import("./pages/Hub/ShoppingLists/Edit"))
const NewShoppingList = lazy(() => import("./pages/Hub/ShoppingLists/NewShoppingList"))

const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={<ComponentLoading />}>
            <Home />
        </Suspense>,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<ComponentLoading />}>
                        <Landing />
                    </Suspense>
                ),
            },
            {
                path: "/terms",
                element: (
                    <Suspense fallback={<ComponentLoading />}>
                        <Terms />
                    </Suspense>
                ),
            },
            {
                path: "/hub",
                element: (
                    <Suspense fallback={<ComponentLoading />}>
                        <Hub />
                    </Suspense>
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<ComponentLoading />}>
                                <Calendar />
                            </Suspense>
                        )
                    },
                    {
                        path: "/hub/tasks",
                        element: (
                            <Suspense fallback={<ComponentLoading />}>
                                <Tasks />
                            </Suspense>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <TaskOverview />
                                    </Suspense>
                                )
                            },
                            {
                                path: "/hub/tasks/new-task/",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <NewTask />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "/hub/tasks/new-task-type/",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <NewTaskType />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "/hub/tasks/:id",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <EditTask />
                                    </Suspense>
                                ),
                            }
                        ]
                    },
                    {
                        path: "/hub/shopping-lists",
                        element: (
                            <Suspense fallback={<ComponentLoading />}>
                                <ShoppingLists />
                            </Suspense>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <List />
                                    </Suspense>
                                )
                            },
                            {
                                path: "/hub/shopping-lists/new/",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <NewShoppingList />
                                    </Suspense>
                                )
                            },
                            {
                                path: "/hub/shopping-lists/:id",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <EditShoppingList />
                                    </Suspense>
                                )
                            }
                        ]
                    },
                    {
                        path: "/hub/settings",
                        element: (
                            <Suspense fallback={<ComponentLoading />}>
                                <Settings />
                            </Suspense>
                        ),
                        children: [
                            {
                                index: true,
                                path: "/hub/settings/family",
                                element: (
                                    <Suspense fallback={<ComponentLoading />}>
                                        <FamilySettings />
                                    </Suspense>
                                )
                            }
                        ]
                    },
                    {
                        path: "/hub/chat",
                        element: (
                            <Suspense fallback={<ComponentLoading />}>
                                <ChatHub />
                            </Suspense>
                        )
                    }
                ]
            }
        ]
    },
    {
        path: "/signup",
        element: (<Suspense fallback={<ComponentLoading />}>
            <Signup />
        </Suspense>)
    }
]);

export default function Router() {
    return <RouterProvider router={router} />;
}