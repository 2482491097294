export function encrypt(string) {
    let encrypted = '';
    for (let i = 0; i < string.length; i++) {
        let charCode = string.charCodeAt(i);
        encrypted += String.fromCharCode(charCode + 1);
    }
    return encrypted;
}

export function decrypt(string) {
    let decrypted = '';
    for (let i = 0; i < string.length; i++) {
        let charCode = string.charCodeAt(i);
        decrypted += String.fromCharCode(charCode - 1);
    }
    return decrypted;
}