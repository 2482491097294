import React, { useState, useContext } from "react";
import HubContext from "../../../context/HubContext";
import Calendar from "../../../components/Calendar";
import Loading from "../../../components/Loading";
import moment from "moment-timezone";
import Toast from "../../../components/Toast";
import { createTask } from "../../../clients/tasks";

const taskIntervals = [
    { id: 0, name: "Only Once" },
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 4, name: "Monthly" },
    { id: 8, name: "Yearly" },
    { id: 16, name: "Every Monday" },
    { id: 32, name: "Every Tuesday" },
    { id: 64, name: "Every Wednesday" },
    { id: 128, name: "Every Thursday" },
    { id: 256, name: "Every Friday" },
    { id: 512, name: "Every Saturday" },
    { id: 1024, name: "Every Sunday" },
    { id: (16 + 32 + 64 + 128 + 256), name: "Every Weekday" },
    { id: (512 + 1024), name: "Every Weekend" }
]


// type NewTaskCommand struct {
// 	Name         string             `json:"name"`
// 	Description  string             `json:"description"`
// 	TaskType     primitive.ObjectID `bson:"task_type" json:"task_type"`
// 	UserAssigned primitive.ObjectID `json:"user_assigned"` // Reference to User
// 	Interval     int                `json:"task_interval"`
// 	StartDate    string             `json:"start_date,omitempty" bson:"start_date,omitempty"`
// 	EndDate      string             `json:"end_date,omitempty" bson:"end_date,omitempty"`
// }

export default function NewTask() {
    const { activeFamily, user } = useContext(HubContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState({ show: false, message: "", type: "", title: "" });
    const [selectedType, setSelectedType] = useState(null);
    const [taskData, setTaskData] = useState({});
    const [taskDate, setTaskDate] = useState(null);
    const [taskDuration, setTaskDuration] = useState({
        days: 0,
        hours: 0,
        minutes: 0
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            if (!taskDate || !selectedType || !taskData?.name || !taskData?.user_assigned) {
                setFeedbackMessage({
                    show: true,
                    message: "We are missing data to be able to create a task. We need a date, type, name and a user assigned.",
                    title: "Missing Fields",
                    type: "warning",
                })
                return;
            }

            // Add the duration to the task start time.
            let timeIndex = 0;
            if (taskData?.time) {
                let timeParts = taskData?.time.split(":");
                timeIndex = parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
            }

            // add the task duration to a new date object based on the task start date.
            let durationInMinutes = 0;
            if (taskDuration?.days > 0) {
                durationInMinutes += parseInt(taskDuration?.days * 24 * 60);
            }
            if (taskDuration?.hours > 0) {
                durationInMinutes += parseInt(taskDuration?.hours * 60);
            }
            if (taskDuration?.minutes > 0) {
                durationInMinutes += parseInt(taskDuration?.minutes);
            }

            // Parse the start date based on the task date and time index
            let startDate = moment.tz(taskDate, user.timezone); // Set the date to the correct timezone
            let hours = Math.floor(timeIndex / 60);
            let minutes = timeIndex % 60;
            startDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

            const res = await createTask({
                ...taskData,
                start: startDate.toDate(),
                task_type: selectedType?.id,
                family_id: activeFamily.id,
                duration: durationInMinutes,
            });

            if (res?.status === 201) {
                setFeedbackMessage({
                    show: true,
                    message: "You have successfully created a new Task",
                    title: "Success",
                    type: "success",
                })
            } else {
                setFeedbackMessage({
                    show: true,
                    message: "Something happend and the task creation failed. Please try again shortly.",
                    title: "Failed",
                    type: "error",
                })
            }
        } catch (err) {
            console.error(err);
            setFeedbackMessage({
                show: true,
                message: "Something happend and the task creation failed. Make sure you have all the necessary rights and have created a task for an active user.",
                title: "Failed",
                type: "error",
            })
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="task__creator">
            <form onSubmit={handleSubmit}>
                <h1>Create New Task</h1>
                <label>
                    <span>Name</span>
                    <input type="text" value={taskData?.name ?? ""}
                        onChange={(e) => {
                            setTaskData({ ...taskData, name: e.target.value });
                        }}
                    />
                </label>
                <label>
                    <span>Description</span>
                    <textarea
                        value={taskData?.description ?? ""}
                        onChange={(e) => {
                            setTaskData({ ...taskData, description: e.target.value });
                        }}
                    ></textarea>
                </label>
                <label>
                    <span>Member</span>
                    <select
                        onChange={(e) => {
                            setTaskData({ ...taskData, user_assigned: e.target.value });
                        }}
                    >
                        <option>Family Member</option>
                        {activeFamily?.members?.map((member) => {
                            return (
                                <option key={member.id} value={member.id}>{member.firstname}</option>
                            )
                        })}
                    </select>
                </label>
                <label>
                    <span>Interval</span>
                    <select
                        onChange={(e) => {
                            let interval = parseInt(e.target.value);
                            setTaskData({ ...taskData, task_interval: interval });
                        }}
                    >
                        <option value={0}>Select Interval</option>
                        {taskIntervals.map((interval) => {
                            return (
                                <option key={interval.id} value={interval.id}>{interval.name}</option>
                            )
                        })}
                    </select>
                </label>
                <div>
                    <span>Type</span>
                    <div className="task_type__container">
                        {activeFamily?.taskTypes?.map((taskType) => {
                            let isSelected = false;
                            if (selectedType?.id === taskType.id) {
                                isSelected = true;
                            }
                            return (
                                <div key={taskType.id} value={taskType.id} className="task_type__card" style={{
                                    border: "1px solid " + taskType.hex_color,
                                    backgroundColor: isSelected ? taskType.hex_color : "rgba(255,255,255,0.3)",
                                }}
                                    onClick={() => {
                                        setSelectedType(taskType);
                                    }}
                                >
                                    <i className={taskType.icon} ></i>
                                    {taskType.name}</div>
                            )
                        })}
                    </div>
                </div>
                <div>
                    <button type="submit" className="cta">Create Task</button>
                </div>
            </form>
            <div className="new__task__details">
                <h2>{taskData?.task_interval > 0 ? "Select Start Date" : "Select Task Date"}</h2>
                <Calendar date={selectedDate} setDate={setSelectedDate} dateClicked={taskDate} onDateClick={setTaskDate} />
                <label>
                    <span>Select Time of Day</span>
                    <input type="time" value={taskData?.time ?? ""}
                        onChange={(e) => {
                            setTaskData({ ...taskData, time: e.target.value });
                        }}
                    />
                </label>
                <div>
                    <h2>{"Set Task Duration"}</h2>
                    <div>
                        <div>
                            <span>Days</span>
                            <input type="number" value={taskDuration?.days ?? 0}
                                onChange={(e) => {
                                    setTaskDuration((prev) => {
                                        return {
                                            ...prev,
                                            days: e.target.value
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <span>Hours</span>
                            <input type="number" value={taskDuration?.hours ?? 0}
                                onChange={(e) => {
                                    setTaskDuration((prev) => {
                                        return {
                                            ...prev,
                                            hours: e.target.value
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <span>Minutes</span>
                            <input type="number" value={taskDuration?.minutes ?? 0}
                                onChange={(e) => {
                                    setTaskDuration((prev) => {
                                        return {
                                            ...prev,
                                            minutes: e.target.value
                                        }
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {feedbackMessage?.show && <Toast message={feedbackMessage.message} title={feedbackMessage.title} type={feedbackMessage.type} close={() => {
                setFeedbackMessage({
                    show: false,
                    message: "",
                    title: "",
                    type: "",
                })
            }} />
            }
            {isLoading && <Loading />}
        </div >
    )
}
