import axios from "axios";
import { getToken, saveToken } from "../utilities/tokenHandler";
const apiUrl = process.env.REACT_APP_API_URL;

export async function createNewUser(data, language) {
    const res = await axios.post(`${apiUrl}/users`, { ...data, language: language }, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return res;
}

export async function userLogin(data) {
    const res = await axios.post(`${apiUrl}/users/login`, data, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
    if (res?.status === 200) {
        saveToken(res?.data?.token ?? "")
    }
    return res;
}

export async function getActiveSession() {
    let token = getToken();
    if (!token || token === "") {
        return;
    }

    const res = await axios.post(`${apiUrl}/users/session`, { test: "test" }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
    return res;
}