import axios from "axios";
import { getToken } from "../utilities/tokenHandler";
const apiUrl = process.env.REACT_APP_API_URL;

export async function createTaskType(data) {
    let token = getToken();
    if (!token) {
        return;
    }

    const res = await axios.post(`${apiUrl}/taskTypes`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    return res;
}

export async function getFamilyTaskTypes(familyId) {
    let token = getToken();
    if (!token) {
        return;
    }

    const res = await axios.get(`${apiUrl}/families/${familyId}/taskTypes`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    return res;
}