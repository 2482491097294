import React, { useEffect, useState, useRef, useContext } from "react";
import { encrypt, decrypt } from "../../../utilities/encryption";
import HubContext from "../../../context/HubContext";
import Toast from "../../../components/Toast";
import "./chat.scss";

export default function ChatHub() {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [feedbackMessage, setFeedbackMessage] = useState({ show: false, message: "", type: "", title: "" });
    const { activeFamily, user } = useContext(HubContext);

    const messageInput = useRef(null);
    const webSocketUrl = "fo-server-dev-765f2445a3d5.herokuapp.com/ws"

    useEffect(() => {
        // Create a new WebSocket connection
        const protocol = window.location.protocol === "https:" ? "wss" : "ws";
        const ws = new WebSocket(`${protocol}://${webSocketUrl}`);

        setSocket(ws);
        // Connection opened
        ws.addEventListener("open", (event) => {
            try {
                let message = `${user.firstname} - Connected to the chat`;
                message = encrypt(message);
                const messageObject = {
                    sender: user.id,
                    family: activeFamily.id,
                    message: message
                };
                // Send the message to the server
                let jsonMessage = JSON.stringify(messageObject);
                ws.send(jsonMessage);
            } catch (err) {
                console.error(err);
            }
        });

        // Listen for messages
        ws.addEventListener("message", (event) => {
            try {
                let messageObject = JSON.parse(event.data);
                messageObject.message = decrypt(messageObject.message);
                setMessages((prevMessages) => [messageObject, ...prevMessages]);
            } catch (err) {
                console.error(err);
            }
        });

        // Handle connection close
        ws.addEventListener("close", (event) => {
            console.log("WebSocket connection closed:", event);
            setFeedbackMessage({
                show: true,
                message: "Connection to the chat server has been closed.",
                title: "Connection Closed",
                type: "warning",
            })
        });

        // Handle connection errors
        ws.addEventListener("error", (event) => {
            console.error("WebSocket error:", event);
            setFeedbackMessage({
                show: true,
                message: "An error has occurred with the chat server. Please try again later.",
                title: "Connection Error",
                type: "error",
            })
        });

        // Cleanup function: close WebSocket on component unmount
        return () => {
            ws.close();
            setSocket(null);
        };
        // eslint-disable-next-line
    }, []);

    const sendMessage = (e) => {
        e.preventDefault();

        console.log(user);
        console.log(activeFamily);
        if (socket && socket.readyState === WebSocket.OPEN) {
            const messageObject = {
                sender: user.id,
                family: activeFamily.id,
                message: encrypt(messageInput.current.value),
            };
            let jsonMessage = JSON.stringify(messageObject);
            socket.send(jsonMessage);
            messageInput.current.value = ""; // Clear the input field after sending
        } else {
            console.error("WebSocket is not open.");
        }
    };

    return (
        <>
            <div id="hub__chat">
                <h1 className="styled__heading">Chat Hub</h1>
                <div className="hub__chat__messages">
                    {messages.map((message, index) => {
                        console.log(message);
                        return (
                            <div className={message?.sender === user.id ? `chat__message own__message` : `chat__message`} key={index}>{message?.message}</div>
                        )
                    })}
                </div>
                <div>
                    <form autoComplete="off" onSubmit={sendMessage}>
                        <input type="text" ref={messageInput} placeholder="Type your message..." />
                        <button type="submit">Send</button>
                    </form>
                    <span className="status__string">Status: {socket?.readyState === WebSocket.OPEN ? "Connected" : "Disconnected"}</span>
                </div>
            </div>
            {feedbackMessage?.show && <Toast message={feedbackMessage.message} title={feedbackMessage.title} type={feedbackMessage.type} close={() => {
                setFeedbackMessage({
                    show: false,
                    message: "",
                    title: "",
                    type: "",
                })
            }} />
            }
        </>
    );
}
