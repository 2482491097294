import React from "react";
import "./loading.scss";

export default function Loading() {
    return (
        <>
            <div id="loading__background"></div>
            <div id="loading__container">
                <span className="loading__title">Loading...</span>
            </div>
        </>
    )
}