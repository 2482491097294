import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import getLandingLanguage from "../../context/language/landing";
import moment from 'moment-timezone';
import "./signup.scss";
import { createNewUser } from "../../clients/users";
import Toast from "../../components/Toast";
import Loading from "../../components/Loading";

export default function Signup() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        countryCode: '',
        phone: '',
        password: '',
        timezone: ''
    });
    const timezones = moment.tz.names();

    useEffect(() => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setFormData((prev) => ({ ...prev, timezone: userTimezone }));
    }, []);


    const [toastMessage, setToastMessage] = useState({
        type: "",
        message: "",
        title: "",
        show: false
    })
    let activeLanguage = getLandingLanguage(navigator.languages);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const res = await createNewUser(formData, activeLanguage?.code);
            if (res?.status === 201) {
                setToastMessage({
                    type: "success",
                    message: "You are now registered",
                    title: "Success",
                    show: true,
                })
            } else {
                setToastMessage({
                    type: "warning",
                    message: "An error occured...",
                    title: "warning",
                    show: true,
                });
            }

        } catch (err) {
            setToastMessage({
                type: "error",
                message: "",
                title: "Error",
                show: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} autoComplete="on" className="signup__main">
                <div className="signup__form__header">
                    <h1 className="styled__heading">{activeLanguage?.title ?? "Family Organizer"}</h1>
                    <img src="/logo.png" alt="black and white family organizer logo, calendar style with a vectorized family and some stylized markers representing days on a calendar" />
                    <p>{activeLanguage?.subtitle ?? ""}</p>
                </div>
                <div className="signup__form__container">
                    <h1>{activeLanguage?.form?.signup ?? "Sign up"}</h1>
                    <label htmlFor="given-name">
                        <span>{activeLanguage?.form?.firstname ?? "First Name"}</span>
                        <input
                            type="text"
                            name="firstname"
                            autoComplete="given-name"
                            id="given-name"
                            value={formData.firstname}
                            onChange={handleChange}
                            minLength={3}
                            required
                        />
                    </label>
                    <label htmlFor="family-name">
                        <span>{activeLanguage?.form?.lastname ?? "Last Name"}</span>
                        <input
                            type="text"
                            name="lastname"
                            autoComplete="family-name"
                            id="family-name"
                            minLength={4}
                            value={formData.lastname}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label htmlFor="email">
                        <span>{activeLanguage?.form?.email ?? "Email"}</span>
                        <input
                            type="email"
                            name="email"
                            autoComplete="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <div className="phone__container">
                        <label htmlFor="country-code">
                            <span>{activeLanguage?.form?.sufix ?? "Sufix"}</span>
                            <input
                                type="text"
                                name="countryCode"
                                autoComplete="tel-country-code"
                                id="country-code"
                                value={formData.countryCode}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label htmlFor="phone">
                            <span>{activeLanguage?.form?.phone ?? "Phone"}</span>
                            <input
                                type="tel"
                                name="phone"
                                autoComplete="tel"
                                id="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <label>
                        <span>Timezone</span>
                        <select
                            value={formData?.timezone ?? ""}
                            required
                            onChange={(e) => setFormData((prev) => ({ ...prev, timezone: e.target.value }))}
                        >
                            {timezones.map((timezone) => (
                                <option key={timezone} value={timezone}>
                                    {timezone}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label htmlFor="new-password">
                        <span>{activeLanguage?.form?.password ?? "Password"}</span>
                        <input
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            id="new-password"
                            minLength={5}
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <div className="form__toolbar">
                        <button type="submit">
                            <span>{activeLanguage?.form?.submit ?? "Signup"}</span>
                        </button>

                    </div>
                    <Link to="/terms">{activeLanguage?.terms ?? "Terms and Conditions"}</Link>
                </div>
            </form>
            {isLoading && <Loading />}
            {toastMessage?.show && <Toast
                title={toastMessage?.title ?? ""}
                type={toastMessage?.type ?? ""}
                message={toastMessage?.message}
                close={() => {
                    if (toastMessage?.type === "success") {
                        navigate("/hub")
                    }
                }}
            />}
        </>
    )
}